import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import('../views/Layout.vue'),
	redirect: '/index',
	meta: {
	  title: '主页'
	},
	children: [
		{
		  path: '/index',
		  component: () => import('../views/index/index.vue'),
		  name: 'Index',
		  meta: {
			title: '工作台'
		  },
		  children: null
		},
		{
		  path: '/setting',
		  component: () => import('../views/system/setting/index.vue'),
		  name: 'Setting',
		  meta: {
			title: '系统设置'
		  },
		  children: null
		},
		{
		  path: '/admin_role',
		  component: () => import('../views/system/admin_role/index.vue'),
		  name: 'AdminRole',
		  meta: {
			title: '角色管理'
		  },
		  children: null
		},
		{
		  path: '/admin',
		  component: () => import('../views/system/admin/index.vue'),
		  name: 'Admin',
		  meta: {
			title: '人员列表'
		  },
		  children: null
		},
		{
		  path: '/operation',
		  component: () => import('../views/system/operation/index.vue'),
		  name: 'Operation',
		  meta: {
			title: '权限管理'
		  },
		  children: null
		},
		{
		  path: '/operation_log',
		  component: () => import('../views/system/operation_log/index.vue'),
		  name: 'OperationLog',
		  meta: {
			title: '系统日志'
		  },
		  children: null
		},
		{
		  path: '/images',
		  component: () => import('../views/system/images/index.vue'),
		  name: 'Images',
		  meta: {
			title: '图片管理'
		  },
		  children: null
		},
		{
		  path: '/article_type',
		  component: () => import('../views/operate/article_type/index.vue'),
		  name: 'ArticleType',
		  meta: {
			title: '文章类型'
		  },
		  children: null
		},
		{
		  path: '/article',
		  component: () => import('../views/operate/article/index.vue'),
		  name: 'Article',
		  meta: {
			title: '文章管理'
		  },
		  children: null
		},
		{
		  path: '/article_edit',
		  component: () => import('../views/operate/article/edit.vue'),
		  name: 'ArticleEdit',
		  meta: {
			title: '编辑文章'
		  },
		  children: null
		},
		{
		  path: '/menus',
		  component: () => import('../views/operate/menus/index.vue'),
		  name: 'Menus',
		  meta: {
			title: '菜单管理'
		  },
		  children: null
		},
		{
		  path: '/position',
		  component: () => import('../views/operate/position/index.vue'),
		  name: 'Position',
		  meta: {
			title: '广告位管理'
		  },
		  children: null
		},
		{
		  path: '/banners',
		  component: () => import('../views/operate/banners/index.vue'),
		  name: 'Banners',
		  meta: {
			title: '广告管理'
		  },
		  children: null
		},
		{
		  path: '/notice',
		  component: () => import('../views/operate/notice/index.vue'),
		  name: 'Notice',
		  meta: {
			title: '公告管理'
		  },
		  children: null
		},
		{
		  path: '/goods',
		  component: () => import('../views/goods/goods/index.vue'),
		  name: 'Goods',
		  meta: {
			title: '商品管理'
		  },
		  children: null
		},
		{
		  path: '/goods_edit',
		  component: () => import('../views/goods/goods/edit.vue'),
		  name: 'GoodsEdit',
		  meta: {
			title: '商品编辑'
		  },
		  children: null
		},
		{
		  path: '/goods_cat',
		  component: () => import('../views/goods/goods_cat/index.vue'),
		  name: 'GoodsCat',
		  meta: {
			title: '商品分类'
		  },
		  children: null
		},
		{
		  path: '/goods_spec',
		  component: () => import('../views/goods/goods_spec/index.vue'),
		  name: 'GoodsSpec',
		  meta: {
			title: '商品规格'
		  },
		  children: null
		},
		{
		  path: '/user',
		  component: () => import('../views/user/user/index.vue'),
		  name: 'User',
		  meta: {
			title: '会员列表'
		  },
		  children: null
		},
		{
		  path: '/user_detail',
		  component: () => import('../views/user/user/detail.vue'),
		  name: 'UserDetail',
		  meta: {
			title: '会员详情'
		  },
		  children: null
		},
		{
		  path: '/user_grade',
		  component: () => import('../views/user/user_grade/index.vue'),
		  name: 'UserGrade',
		  meta: {
			title: '会员等级'
		  },
		  children: null
		},
		{
		  path: '/order',
		  component: () => import('../views/order/order/index.vue'),
		  name: 'Order',
		  meta: {
			title: '订单管理'
		  },
		  children: null
		},
		{
		  path: '/logistics',
		  component: () => import('../views/operate/logistics/index.vue'),
		  name: 'Logistics',
		  meta: {
			title: '物流公司'
		  },
		  children: null
		},
		{
		  path: '/group',
		  component: () => import('../views/user/group/index.vue'),
		  name: 'Group',
		  meta: {
			title: '团队管理'
		  },
		  children: null
		},
		{
		  path: '/audit_list',
		  component: () => import('../views/audit_list/audit_list/index.vue'),
		  name: 'AuditList',
		  meta: {
			title: '审核业绩'
		  },
		  children: null
		},
		{
		  path: '/manage_mobile',
		  component: () => import('../views/operate/manage_mobile/index.vue'),
		  name: 'Mobile',
		  meta: {
			title: '充值号码'
		  },
		  children: null
		},
	],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
	// 获取token
	let token = Vue.prototype.VueCookies.get("token")
	if(token){ // 已登录
	    // 防止重复登录
		if(to.path === '/login'){
			// Vue.prototype.$message.error('请不要重复登录');
			return next({path:from.path ? from.path : '/'})
		}
		//其他验证
		// 验证权限
		// let rules = window.sessionStorage.getItem('rules')
		// rules = rules ? JSON.parse(rules) : []

		// let index = rules.findIndex(item=>{
		// 	return  item.rule_id > 0 && item.desc === to.name
		// })
		// if(index === -1){
		// 	Vue.prototype.$message.error('你没有权限')
		// 	return next({name:from.name ? from.name : '/'})
		// }
		
	    next();
	}else{
		// 跳过登录页验证
		if(to.path === '/login'){
			return next();
		}
		// 未登录
		// Vue.prototype.$message.error('请先登录');
		next({ path:'/login' })
	}
})

export default router
