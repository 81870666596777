<template>
	<el-dialog title="属性选择" :visible.sync="skuModel" width="80%" top="5vh">
		<el-container style="position: relative;height: 70vh;margin: -30px -20px;">
		  <el-container>
		    <el-aside width="200px" class="border-right bg-white" style="position: absolute;left: 0;top: 0;bottom: 0;">
				<!-- 侧边 -->
		        <ul class="list-group list-group-flush">
                   <li class="list-group-item list-group-item-action" 
				   v-for="(item,index) in sku_list" 
				   :key="index" 
				   :class="sku_active === index ? 'sum-active' : ''"
				   @click="changeSkus(index)">
					   {{item.name}}
				   </li>
				</ul>
			</el-aside>
		    <el-container>
				<el-header class="border-bottom border-top" style="position: absolute;height: 60px; left: 200px;right: 0;top: 0;line-height: 60px;">
					<el-button type="primary" size="mini"
					@click="handerAll"
					>{{isChooseAll ? '取消全选' : '全选'}}</el-button>
				</el-header>
		      <el-main style="position: absolute;left: 200px;top: 60px;bottom: 0;right: 0;">
				  <div class="d-flex flex-wrap">
				  	 <span class="p-2 rounded border mr-2 " style="cursor: pointer;"
					 v-for="(item,index) in skuItems" 
					 :key="index"
					 :class="item.active ? 'sum-active' : ''"
					 @click="changeSkuItem(item)"
					 >
						 {{item.name}}
					  </span>
				  </div>
			  </el-main>
		    </el-container>
		  </el-container>
		</el-container>
		
		<div slot="footer" class="dialog-footer">
			<el-button @click="hide">取 消</el-button>
			<el-button type="primary" @click="confirm">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
export default {
	data() {
		return {
			skuModel: false,
			callback:false,
			chooseList:[],
			sku_active:0,
			sku_list:[],
			skuItems:[],
		}
	},
	created() {
		this.getGoodsSpec()
	},
	computed:{
		// skuItems(){
		// 	return this.sku_list[this.sku_active].list
		// },
		//是否全选
		isChooseAll(){
			return this.skuItems.length === this.chooseList.length
		}
	},
	methods:{
		  getGoodsSpec(){
			  this.axios.get('/manage/goods_spec/skuList').then(res=>{
			  	if(res.status){
			  		this.sku_list = res.data
					if(this.sku_list.length > 0){
						this.skuItems = this.sku_list[this.sku_active].list
					}
			  	}
			  })
		  },
		  chooseSkus(callback){
			this.callback = callback
			this.skuModel = true  
		  },
		  hide(){
			this.unChooseAll()
			this.skuModel = false
			this.callback = false
		  },
		  confirm(){
			  //选中
			  if(typeof this.callback === 'function'){
				  let item = this.sku_list[this.sku_active]
				  this.callback({
					    name:item.name,
						// type:item.type,
					    list:this.chooseList
				  })
			  }
			  //隐藏
			  this.hide()
		  },
		  //切换规格
		  changeSkus(index){
			  this.unChooseAll()
			  this.sku_active = index
			  this.skuItems = this.sku_list[this.sku_active].list
		  },
		  //选择属性
		  changeSkuItem(item){
			  if(!item.active){
				  this.chooseList.push(item)
				  return item.active = true
			  }
			  let index = this.chooseList.findIndex((v)=>{
				  return v.id === item.id
			  })
			  if(index < 0) return
			  this.chooseList.splice(index,1)
			  item.active = false
		  },
		  //全选
		  handerAll(){
			  let list = this.sku_list[this.sku_active].list
			  //已经全选
			  if(this.isChooseAll){
				  return this.unChooseAll()
			  }
			  //全选
			  this.chooseList = list.map(v=>{
				  v.active = true
				  return v
			  })
		  },
		  unChooseAll(){
			  if(this.sku_list.length == 0) return
			  let list = this.sku_list[this.sku_active].list
			  list.forEach(v=>{
			  	  v.active = false
			  })
			  //清空选中列表
			  return this.chooseList = []
		  },
	}
}
</script>

<style scoped>
/deep/ .el-header{
	box-shadow:none;
}
/deep/ .el-aside{
	background-color: #fff;
}
</style>
