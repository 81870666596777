import { Message } from 'element-ui'
export default {
	// sku排列算法
	cartesianProductOf() {
		return Array.prototype.reduce.call(arguments,function(a, b) {
			var ret = [];
			a.forEach(function(a) {
				b.forEach(function(b) {
					ret.push(a.concat([b]));
				});
			});
			return ret;
		}, [[]]);
	},
	swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    // 上移 将当前数组index索引与后面一个元素互换位置，向数组后面移动一位
    moveUp(arr, index) {
      this.swapArray(arr, index, index - 1);
    },
    // 下移 将当前数组index索引与前面一个元素互换位置，向数组前面移动一位
    moveDown(arr, index) {
      this.swapArray(arr, index, index + 1);
    },
	//验证是否是手机号
	isPhoneNumber(str) {
		var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
		if (!myreg.test(str)) {
			return false
		} else {
			return true
		}
	},
	success(msg){
		Message.success({
			message: msg,
			type: 'success'
		});
	},
	error(msg){
		Message.error(msg);
	},
	//判断是否是数字
	isNumber(val){
		var regPos = /^\d+(\.\d+)?$/; //非负浮点数
		var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
		if(regPos.test(val) || regNeg.test(val)){
			return true;
		}else{
			return false;
		}
	},
	fullScreen(){
		// 全屏
		var docElm = document.documentElement;
		//W3C
		 if (docElm.requestFullscreen) {
			 docElm.requestFullscreen();
		 }
			 //FireFox
		 else if (docElm.mozRequestFullScreen) {
			 docElm.mozRequestFullScreen();
		 }
			 //Chrome等
		 else if (docElm.webkitRequestFullScreen) {
			 docElm.webkitRequestFullScreen();
		 }
			 //IE11
		 else if (elem.msRequestFullscreen) {
			 elem.msRequestFullscreen();
		 }
	},
	exitFullScreen(){
		// 退出全屏
		if (document.exitFullscreen) {
		   document.exitFullscreen();
		}
		  else if (document.mozCancelFullScreen) {
			  document.mozCancelFullScreen();
		}
		  else if (document.webkitCancelFullScreen) {
			  document.webkitCancelFullScreen();
		}
		  else if (document.msExitFullscreen) {
			  document.msExitFullscreen();
		}
	},
	// 清除所有cookie
	clearCookies() {
	  var temp = document.cookie.split(';')
	  var name
	  for (var i = 0; ; i++) {
	    if (!temp[i]) break
	    name = temp[i].split('=')[0]
	    var exp = new Date()
	    exp.setTime(exp.getTime() - 1)
	    document.cookie = name + "= ''" + '; expires=' + exp.toGMTString()
	  }
	}
}
